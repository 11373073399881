body {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: black;
}

.MenuBar-Container
{
    width: 750px;
    height: 750px;
    background-color: black(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 20px;

}

span{
    cursor: pointer;
    width: 100%;

}


.title-span {
    cursor: pointer;
    width: 100%;
    display: block; 
    text-align: center; 
    padding: 10px; 
    background-color: black; 
    border-radius: 5px; 
    color: white;
    font-style: normal;
    font-size: large;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.title-span:hover {
    background-color: rgba(51, 77, 77, 0.2); 

}

ul{
    font-size: 18px;
    line-height: 1.5;
    padding-left: 20px;
    margin-bottom: 20px;
}

ul li {
   margin-bottom: 5px;
}

h5{
    margin-top: 20px;
}

.Modal .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
}

.Modal .modal-content {
    height: 750px; 
    max-width: 100%; 
    margin: auto;
}

.Modal .modal-body {
    overflow-y: auto; 
}
.Modal.fade .modal-dialog {
    transition: transform 0.3s ease-out, opacity 0.3s ease-out; 
}

.Modal.fade.show .modal-dialog {
    transform: none; 
    opacity: 1;
}

.Modal.fade .modal-dialog {
    transform: translate(0, -25%); 
    opacity: 0; 
}



@media (max-width: 768px) {
    .MenuBar-Container {
        width: 100%;
        height: auto;
        padding: 10px;
    }
    ul{
        font-size: 14px;
        line-height: 1.5;
        padding-left: 5px;
        margin-bottom: 15px;
    }
    
    ul li {
       margin-bottom: 8px;
    }
    
    h5{
        margin-top: 20px;
    }
    .title-span {
        font-size: medium;
        padding: 8px;
    }
}

@media (max-width: 576px) {
    .MenuBar-Container {
        flex-direction: column;
        width: 100%;
    }

    .title-span {
        text-align: center;
        font-size: small;
    }
}