* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  
}
.pagecomponent{
  background-color: black;
opacity: 0.9;
height: 100vh;
width: 100vw;
}

.PreReleaseContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

 
}

.scroll-container {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 90vw;
  height: 80vh; 
  overflow-y: scroll;
  scroll-behavior: smooth;
  top: 5vh;
  position: relative;
  z-index: 3;
  
}

.scroll-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  font-size: 1em;
  color: rgb(255, 255, 255);
  z-index: 3;
  opacity: 1;
  animation: fadeInUp 2s ease forwards;
  position: relative;
}


.scroll-page .selectsize {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.scroll-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.scroll-controls button {
  margin: 0 0px; 
  width: 110px;
}




h1.HomeTopTitle {
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1.5rem, 5vw, 3rem);
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #ff0000, #0084ff, white, red);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5), 0 0 25px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 1200px;
}

.LearnReflect {
  margin-top: 10vh;
}




p {
  color: #dbdada;
  text-align: center;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: bold;
  line-height: 1.6;
  padding: 10px;
  width: 90%;
  max-width: 770px;
  margin: 20px auto;
  border-bottom: 2px solid #ddd;
  font-style: oblique;
}

img.AI1 {
  height: 400px;
  max-width: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .PreReleaseContainer {
    padding: 20px;
  }

  h1.HomeTopTitle {
    font-size: clamp(1.2rem, 4vw, 2.5rem);
    top: 10px;
  }

  img.AI1 {
    height: auto;
    width: 90%;
  }

  p {
    font-size: clamp(0.9rem, 3vw, 1.2rem);
    width: 100%;
  }
}

h3 {
  color: grey;
  z-index: 2;
  font-size: clamp(1.5rem, 5vw, 3em); 
  position: relative;
  margin-top: 2px;

  display: flex;
  text-align: center; 
  font-size: x-large;
  justify-content: center;
  font-style: italic  ;
}



h2 {
  color: white;
  z-index: 2;
  position: relative;
  top: 5vh;
  font-size: 3em;
  font-style: italic;
  color: grey;
  display: flex;
}






.Whitelist {
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  margin: auto; /* Increased vertical spacing */
}
@media (max-width: 768px) {
  .Whitelist {
    margin: 25px 0; /* Adjust for smaller screens */
  }
}


.P-EarlyAccess{
  color: grey;
  width: 375px;
  font-size: large;
}


.Donation{


}


.Donation-button{
  border-radius: 30px;
  height: 80px;
  width: 50vh;
  text-align: center;
  font-size: 1.7em;
  background-color: black;
  color: white;
  border-color: white;
  margin: 2px solid white
  ;
}


.LR-LOGO{
height: 25%;
margin-top: 130px;
align-items: center;
justify-content: center;}