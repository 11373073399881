/* Contact.css */
.ContactDiv {
    max-width: 500px; /* Maximum width for larger screens */
    width: 50vw; /* Use 90% width to allow for padding on smaller screens */
    height: auto; /* Allow height to adjust automatically */
    background-color: black;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Padding inside the div */
    border: 1px solid #ccc;
    border-radius: 12px;
    margin: 20px auto; /* Center the div horizontally */
}

label {
    text-align: center;
    margin-bottom: 10px;
    
}

select {
    margin-bottom: 10px;
    font-size: medium;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: black;
    opacity: 0.9;
    color: white;
}

input {
    text-align: center;
    margin-bottom: 10px;
    background-color: black;
    color: white;
}

form {
    width: 100%; /* Keep form full width */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    opacity: 0.9;
}

button {
    background-color: black;
    color: white;
    width: 100px; /* Fixed button width for larger screens */
    height: 50px; /* Fixed button height for larger screens */
    border-radius: 12px;
    margin-top: 10px;
    font-size: 1rem; /* Base font size for buttons */

}

.Description {
    width: 100%; /* Full width for description */
    height: 250px; /* Fixed height for larger screens */
    text-align: left;
    margin-top: 0px;
    padding: 10px;
    resize: none;
    overflow: auto;
    background-color: black;
    color: white;
}

/* Responsive Design Adjustments */
@media (max-width: 1200px) {
    .ContactDiv {
        max-width: 100%; /* Allow full width on mobile */
        padding: 10px; /* Reduced padding for mobile */
    }

    button {
        width: 80%; /* Full width buttons on mobile */
        height: 40px; /* Smaller height for buttons */
        font-size: 1rem; /* Adjust font size for mobile */
    }

    .Description {
        height: 200px; /* Slightly smaller height for description on mobile */
    }
    
    input, select {
        width: 100%; /* Ensure input and select elements are full width */
        font-size: 1rem; /* Make font size responsive */
    }
}


@media (min-width: 700px) {
    button {
        width: 120px; /* Wider buttons on larger screens */
        height: 50px; /* Maintain button height */
        font-size: 1.1rem; /* Increase font size on larger screens */
    }

    .Description {
        height: 250px; /* Original height for larger screens */
    }

    input, select {
        font-size: 1.1rem; /* Slightly larger font size on larger screens */
    }
}
