.stuker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background-size: cover;
    z-index: -1;
}

.checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 1050px;
    width: 750px;
    border-radius: 12px;
    background-color: #333;
    z-index: 1;
    padding: 20px;
    color: white;
}

.SoftWareImg {
    width: auto;
    height: 50vh;
    margin-bottom: 20px;
}



.payment-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.currency-select {
    width: 150px;
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 25px 0;
    cursor: pointer;
    background-color: #333;
    color: grey;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.currency-select:hover {
    background-color: #e0e0e0;
    color: #333;
}

.option {
    font-size: 16px;
    padding: 10px;
}

label {
    color: white;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 10px;
}

.nav-button {
    width: 75px; 
    border-radius: 30px;
    height: 90px;
    cursor: pointer;
}

.EmailInput{
    width: 50%;
}