.Image-Scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  
}


.navigation-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.nav-button {
    width: 70px; 
    height: 40px;
    cursor: pointer;
}
.image-container {
    height: 550px; 
    display: flex;
    justify-content: center; 
    align-items: center
}

.SoftWareImg {
    height: 90%;
    width: 100%; 
    margin-top: 60px;
}


@media (max-width: 768px) {
    .image-container {
        height: 300px; 
    }

    .SoftWareImg {
        height: auto; 
        width: 100%;
        margin-top: 30px; 
    }

    .nav-button {
        width: 50px;
    }

    .navigation-buttons {
        gap: 10px;
    }
}