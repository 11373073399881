.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 500px;
  height: 300px;
  max-width: 80%;
  box-shadow: 0 0 20px 5px rgba(225, 241, 0, 0.8),
    0 0 40px 10px rgba(0, 255, 128, 0.8), 0 0 60px 15px rgba(0, 0, 255, 0.8);
}

.modal {
  display: block;
  position: relative;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}
.P-Recieved {
  color: lightgrey;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: normal;
}

.close-button {
  width: 100%;
  height: 25%;
  background-color: black;
  color: white;
  cursor: pointer;
}

label {
  color: black;
  margin-bottom: 10px;
  text-align: center;
}

.EmailForm {
  display: flex;
  background-color: black;
  width: 100%;
  max-width: 300px;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 185px;
  text-align: center;
  margin: 20px auto;
  margin-top: 20px;
}

.submitbutton {
  width: 100%;
  height: 35px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitbutton:hover {
  background-color: #0056b3;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: lightgrey;
  color: black;
} 

label {
  color: black;
  margin-bottom: 10px;
  text-align: center;
}


p {
    color: white;
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    font-weight: bold;
    line-height: 1.6;
    padding: 10px;
    width: 90%;
    max-width: 770px;
    margin: 20px auto;
    border-bottom: 2px solid #ddd;
    font-style: oblique;
  }


  .Whitelist-Container{
    position: fixed;
    display: flex;
    background-color: grey;
    width: 250px;
    height: 100px;
    margin-bottom: 50px;
  }




.P-Early{
  color: white;
}
p.Thankyou{
  color: grey;
}